.sucessMessage{
    min-height: 100vh;
}
.sucessMessageWrap{
    background-color:#f2f2f2;
    border-radius:10px;
    padding:30px;
    text-align: center;
}
.sucessMessageWrap h4{
    font-size:34px;
    font-weight:300;
    margin:0;
    margin-bottom:10px;
}
.sucessMessageWrap p{
    margin-top:0px;
    font-size:16px;
    line-height:20px;
    color:#333;
    padding:0 20px;
}