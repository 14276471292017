.resultado-dialog .resultado-positivo{
   color: #2EA844;
   font-weight: bold;
   font-size: 16px;
   padding-top: 20px;
}
.resultado-dialog .resultado-positivo svg{
   font-size: 30px;
   float: left;
   margin-right: 4px;
   margin-top: -6px;
}

.resultado-dialog .resultado-negativo{
   color: #DD0017;
   font-weight: bold;
   font-size: 16px;
   padding-top: 20px;
}
.resultado-dialog .resultado-negativo svg{
   font-size: 30px;
   float: left;
   margin-right: 4px;
   margin-top: -6px;
}