.etiquetaWrap{
   tab-interval:30.4pt;
   word-wrap:break-word
}

.MsoNormal{
   text-align:center
}
.MsoNormal span{
   font-size:10.0pt
}

p.MsoNormal,
li.MsoNormal,
div.MsoNormal {
  margin-top: 0cm;
  margin-right: 0cm;
  margin-bottom: 8.0pt;
  margin-left: 0cm;
  line-height: 107%;
  font-size: 11.0pt;
  font-family: "Calibri", sans-serif;
}

a:link,
span.MsoHyperlink {
  color: #0563C1;
  text-decoration: underline;
}

a:visited,
span.MsoHyperlinkFollowed {
  color: #954F72;
  text-decoration: underline;
}

.MsoChpDefault {
  font-size: 11.0pt;
  font-family: "Calibri", sans-serif;
}

.MsoPapDefault {
  margin-bottom: 8.0pt;
  line-height: 107%;
}

@page WordSection1 {
  size: 5.0cm 3.0cm;
  margin: 0cm;
  mso-paper-source: 0;
}

div.WordSection1 {
  page: WordSection1;
}

.tagWrapper{
  height:2.9cm;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;

  align-items: center;
}
.tagInside{
  width:100%;
}
.tagInside p{
  margin:0px;
}