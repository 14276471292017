.error,
.warning,
.default{
   border-radius: 18px;
   padding: 4px;
   text-align: center;
   line-height: 14px;
   vertical-align: middle;
   display: flex;
   align-items: center;
   justify-content: center;
   font-size:12px;
   color:white;
   font-weight:bold;
   max-width:100px;
}
.error{
   background-color: #DD0017;
}
.warning{
   background-color: #CCB528;
}
.default{
   background-color:#ccc;
   color:black;
}

.error svg,
.warning svg,
.default svg{
   font-size:20px;
   margin-right:4px;
}

.tableOperacion .MuiTableCell-root{
   padding:4px 10px;
   font-size:12px !important;
}

.custom-table-title h6{
   vertical-align: middle;
   line-height: 20px;
   display: flex;
}
.custom-table-title svg{
   margin-right:6px;
}