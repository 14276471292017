.card-asignacion{}
.card-asignacion h2{
   font-size:24px;
   line-height: 28px;
   margin:0px;
   padding:0px;
}
.card-asignacion h4{
   font-size:14px;
   line-height: 18px;
   margin:0px;
   padding:0px;
}

.card-asignacion small{
   font-size: 10px;
   color:gray;
   text-transform: uppercase;
   letter-spacing: 0.5px;;
}
.circle-icon{
   background-color:#f2f2f2;
   border-radius: 100%;
   padding:10px;
   height: 45px;
   width:45px;;
}
.circle-icon svg{
   color:#999;
}

.color-encurso{
   background-color:rgb(212, 219, 255);
}
.color-encurso svg{
   color:rgb(63, 90, 241);
}

.color-pagados{
   background-color:rgb(212, 255, 218);
}
.color-pagados svg{
   color:rgb(46, 190, 65);
}

.color-cancelados{
   background-color:rgb(255, 212, 212);
}
.color-cancelados svg{
   color:rgb(224, 58, 58);
}

.badge-pendiente{
   background-color:#f2f2f2;
   color:#333;
   padding:4px 10px;
   border-radius:15px;
}
.badge-encurso{
   background-color:rgb(212, 219, 255);
   color:rgb(63, 90, 241);  
   padding:4px 10px;
   border-radius:15px;
}
.badge-cancelado{
   background-color:rgb(255, 212, 212);
   color:rgb(224, 58, 58);
   padding:4px 10px;
   border-radius:15px;
}

.badge-pagado{
   background-color:rgb(212, 255, 218);
   color:rgb(46, 190, 65);
   padding:4px 10px;
   border-radius:15px;
}


.asignacion-detalle small{
   font-size: 11px;
   color:#999
}
.asignacion-detalle small strong{
   font-size:13px;
   color:black;
   font-weight: normal;
}
.asignacion-detalle h2{
   font-size:18px;
}
.asignacion-detalle h4{
   font-weight: 500;
   color:black;
   margin-bottom: 10px;
}